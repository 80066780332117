/**
 * Type: ページ
 * What: 団体利用LP
 */
import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import Image from '../../util/Image';

const pageTitle = 'イベントホール（貸しホール）ご利用案内';
const pageDescription =
  '6Fイベントホールは、多目的にご利用いただける貸ホールです。最先端のアートからサブカルチャーまで様々な展示会、イベント等でご利用いただけます。';
const pageSlug = 'eventhall';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <h3 className="headline pc-mb60 sp-mb30">
          <span>イベントをお考えの方へ</span>
        </h3>
        <div className="inner trans bg-13 inner-bg">
          <div className="flex pc-mb60 sp-mb30">
            <div className="half auto sp-mb20">
              <Image src="img-eventhall-1.png" />
            </div>
            <div className="half col-right">
              <p className="text is-3 is-3-sp pc-mb20 sp-mb10">
                6Fイベントホールは、多目的にご利用いただける貸ホールです。
              </p>
              <p className="text is-3 is-3-sp pc-mb20 sp-mb10">
                総面積748.4㎡(257坪)、303㎡
                〜から利用も可能、最先端のアートからサブカルチャーまで様々な展示会、イベント等でご利用いただけます。
              </p>
              <p className="text is-3 is-3-sp">
                みずほPayPayドームに隣接した「BOSS E・ZO
                FUKUOKA」という視認性の高い立地で、充実した附帯設備や搬出入設備により円滑なイベント運営を実現します。
              </p>
            </div>
          </div>
          <div className="flex pc-mb60 sp-mb30">
            <div className="alphabet-icon-wrap">
              <Image src="img-eventhall-2.png" />
            </div>
            <div className="alphabet-icon-wrap">
              <Image src="img-eventhall-3.png" />
            </div>
            <div className="alphabet-icon-wrap">
              <Image src="img-eventhall-4.png" />
            </div>
          </div>
          <div className="btn-wrap">
            <a
              href="/eventhall/eventhall-overview"
              className="btn-regular sp-margin-horizontal-auto sp-mb10"
            >
              <span>施設概要</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
            <a
              href="/eventhall/eventhall-price"
              className="btn-regular sp-margin-horizontal-auto sp-mb10"
            >
              <span>利用料金</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
            <a
              href="/eventhall/eventhall-features"
              className="btn-regular sp-margin-horizontal-auto sp-mb10"
            >
              <span>特色・使用実績</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
          </div>
          <div className="btn-wrap">
            <a
              href="/special/pastevent/"
              className="btn-regular sp-margin-horizontal-auto sp-mb10"
            >
              <span>過去のイベント一覧</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
            <a
              href="/files/eventhall.pdf"
              className="btn-regular sp-margin-horizontal-auto sp-mb10"
            >
              <span>各種資料ダウンロード</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
            <a
              href="/inquiry/form/"
              className="btn-regular sp-margin-horizontal-auto sp-mb10"
            >
              <span>お問い合わせ</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
